<template>
  <v-card
    flat
    class="pa-3 mt-2"
  >
    <v-card-text class="d-flex">
      <v-avatar
        rounded
        size="120"
        class="me-6"
      >
        <v-img :src="$store.state.galleryItems[0]"></v-img>
      </v-avatar>
      <div>
        <v-btn
          color="primary"
          class="me-3 mt-5"
          @click="$store.commit('showGallery', { open: true, list: false })"
        >
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Upload new photo</span>
        </v-btn>

        <!-- <input
          ref="refInputEl"
          type="file"
          accept=".jpeg,.png,.jpg,GIF"
          :hidden="true"
        /> -->

        <!-- <v-btn
          color="error"
          outlined
          class="mt-5"
        >
          Reset
        </v-btn> -->
        <p class="text-sm mt-5">
          Allowed JPG, GIF or PNG.
        </p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form class="multi-col-validation mt-6">
        <v-row>
          <v-col
            md="6"
            cols="12"
          >
            <v-text-field
              v-model="accountData.username"
              label="Username"
              dense
              outlined
              class="mb-5"
            >
            <v-icon
              slot="append"
              color="#56ca00"
            >
              {{ icons.mdiCheckCircle }}
            </v-icon>

            <v-icon
              slot="append"
              color="#ff4c51"
            >
              {{ icons.mdiAlertCircle }}
            </v-icon>
          </v-text-field>

            <v-text-field
              v-model="accountData.name"
              label="Name"
              dense
              outlined
              class="mb-5"
            ></v-text-field>

            <v-text-field
              v-model="accountData.email"
              label="E-mail"
              dense
              outlined
              disabled
            ></v-text-field>
          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="6"
            class="d-none d-sm-flex justify-center position-relative"
          >
            <!-- <v-img
              contain
              max-width="170"
              src="@/assets/images/3d-characters/pose-m-1.png"
              class="security-character"
            ></v-img> -->
            <v-textarea
              height="168px"
              outlined
              rows="7"
              label="Bio"
              v-model="accountData.bio"
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="clearfix">
            <!-- <v-divider></v-divider> -->
            <!-- <v-btn
              color="secondary"
              outlined
              type="reset"
              @click.prevent="resetForm"
            >
              Cancel
            </v-btn> -->
            <v-btn
              color="primary"
              class="me-3" @click="updateUserData()"
            >
              Save changes
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiAlertOutline, mdiCloudUploadOutline, mdiCheckCircle, mdiAlertCircle } from '@mdi/js'
// import { ref } from '@vue/composition-api'


export default {
  props: ['accountData'],
  data: () => ({
    icons: {
      mdiAlertOutline,
      mdiCloudUploadOutline,
      mdiCheckCircle,
      mdiAlertCircle
    },
  }),
  methods: {
    updateUserData() {
      this.loading = true;
      axios.post(`${this.endpoint}/updateUserData`, {
        username: this.accountData.username,
        name: this.accountData.name,
        // email: accountData.email
      }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(responseDB => {
        this.loading = false;
        console.log(responseDB);
      });
    },
  }
}
</script>

<template>
  <v-card id="account-setting-card">
    <!-- tabs -->
    <v-tabs
      v-model="tab"
      show-arrows
    >
      <v-tab
        v-for="tab in tabs"
        :key="tab.icon"
      >
        <v-icon
          size="20"
          class="me-3"
        >
          {{ tab.icon }}
        </v-icon>
        <span>{{ tab.title }}</span>
      </v-tab>
    </v-tabs>

    <!-- tabs item -->
    <v-tabs-items v-model="tab">
      <v-tab-item>
        <account-settings-account :account-data="accountSettingData.account"></account-settings-account>
        <v-divider></v-divider>
        <account-settings-security></account-settings-security>

      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import { mdiAccountOutline, mdiLockOpenOutline} from '@mdi/js';
import AccountSettingsAccount from './AccountSettingsAccount.vue';
import AccountSettingsSecurity from './AccountSettingsSecurity.vue';

export default {
  data: () => ({
    accountSettingData : {
      account: {
        avatarImg: require('@/assets/images/avatars/1.png'),
        username: '',
        name: '',
        email: '',
        bio: ''
      },
      icons: {
        mdiAccountOutline,
        mdiLockOpenOutline,
      },
    },
    tab: null,
    tabs: [
      { title: 'Account', icon: mdiAccountOutline },
    ]
  }),
  components: {
    AccountSettingsAccount,
    AccountSettingsSecurity
  },
  watch: {
    '$store.state.user': {
      handler: function(val) {
       if(val) {
        this.updateUserData(val);
       }
      },
      deep: true
    }
  },
  mounted() {
    if(this.$store.state.user) {
      this.accountSettingData.account.name = this.$store.state.user.extra_data.name;
      this.accountSettingData.account.username = this.$store.state.user.extra_data.username;
      this.accountSettingData.account.email = this.$store.state.user.extra_data.email;
    }
  },
  beforeMount() {
    this.$store.commit('setLogin', true);
  },
  methods: {
    updateUserData(data) {
      this.accountSettingData.account.name = data.extra_data.name;
      this.accountSettingData.account.username = data.extra_data.username;
      this.accountSettingData.account.email = data.extra_data.email;
    }
  },
}
</script>

<style>
  #account-setting-card {
    margin-top: 20px;
  }
</style>

<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form>
      <div class="px-3">
        <v-card-title class="flex-nowrap">
          <span class="text-break">Reset Password</span>
        </v-card-title>
        <v-card-text class="pt-5">
          <div class="wrapper-reset-password">
            <v-row v-if="loadingPassword">
              <v-col
                cols="12"
                sm="12"
                md="12"
              >
                <v-progress-circular
                  :size="70"
                  :width="7"
                  color="primary"
                  indeterminate
                  class="d-block ma-auto mt-5"
                ></v-progress-circular>
              </v-col>
            </v-row>

            <v-row v-if="!loadingPassword">
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="resetPassword"
              >
                <div>
                  <v-text-field
                    v-model="validationCode"
                    type="text"
                    label="Validation Code"
                    outlined
                    dense
                    @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
                  ></v-text-field>

                  <v-text-field
                    v-model="newPassword"
                    :type="isNewPasswordVisible ? 'text' : 'password'"
                    :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                    label="New Password"
                    outlined
                    dense
                    hint="Make sure it's at least 8 characters."
                    persistent-hint
                    @click:append="isNewPasswordVisible = !isNewPasswordVisible"
                  ></v-text-field>
                </div>

                <p v-if="loadingPassword" class="mt-5 text-sm text--primary text-center">Updating password...</p>

                <v-btn @click="sendVCode()"
                  color="primary"
                  class="d-block ma-auto mt-5"
                >
                  Update password
                </v-btn>
              </v-col>

              <v-col
                cols="12"
                sm="12"
                md="12"
                class="d-block justify-center position-relative"
                v-if="!resetPassword"
              >
                <p class="text-base font-weight-semibold text--primary text-center">You'll receive an email with a validation code to reset your password.</p>
                <v-btn @click="sendVCode()"
                  color="primary"
                  class="d-block ma-auto"
                >
                  Send the Code
                </v-btn>

                <v-alert v-if="message.status === 0"
                  dense
                  outlined
                  type="error"
                  class="mt-5"
                >
                  {{ message.text }}
                </v-alert>
                <v-alert v-if="message.status === 1"
                  dense
                  text
                  type="success" class="mt-5"
                >
                  {{ message.text }}
                </v-alert>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </div>

      <v-divider></v-divider>

      <div class="pa-3">
        <v-card-title class="flex-nowrap">
          <span class="text-break">Account Privacy</span>
        </v-card-title>

        <v-card-text class="two-factor-auth text-center mx-auto">
          <v-avatar
            color="primary"
            class="primary mb-4"
            rounded
          >
            <v-icon
              size="25"
              color="white"
            >
              {{ userPrivacy ? icons.mdiEye : icons.mdiEyeOff }}
            </v-icon>
          </v-avatar>
          <div class="wrapper-content">
            <template v-if="!loading">
              <p class="text-base text--primary font-weight-semibold switch-profile">
                Your profile is {{ userPrivacy ? 'Public' : 'not Public' }}.
              </p>
              <v-switch v-model="userPrivacy" inset class="switch-content-type text-center mx-auto"></v-switch>
              <p class="text-sm text--primary">
                {{ userPrivacy ? 'Check your categories and files if you want to hide something specific.' : 'This action hides your public profile and will only affect the `/user/username` share link.' }}
              </p>
            </template>
            <v-progress-linear v-if="loading"
              color="primary accent-4 loader"
              indeterminate
              rounded
              height="6"
            ></v-progress-linear>
          </div>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
import axios from "axios";
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline, mdiEye, mdiEyeOff } from '@mdi/js'

export default {
  data () {
    return {
      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
        mdiEye,
        mdiEyeOff
      },
      resetPassword: false,
      isNewPasswordVisible: false,
      validationCode: '',
      newPassword: '',
      userPrivacy: parseInt(localStorage.getItem('is_public'), 10) ? true : false,
      endpoint: process.env.VUE_APP_ROOT_API,
      active: true,
      logged: true,
      loading: false,
      loadingPassword: false,
      message: {
        status: null,
        text: null
      }
    }
  },
  watch: {
    userPrivacy() {
      this.updatePrivacy();
    }
  },
  methods: {
    checkLogin() {
      this.$store.commit('setLogin', true);
      this.active = true;
      this.logged =  true;
      this.searchCategory(-1, 1);
    },
    sendVCode() {
      this.loadingPassword = true;
      axios.post(`${this.endpoint}/resetPassword`, {
        requestCode: this.resetPassword,
        newPassword: this.newPassword,
        validationCode: this.validationCode
      }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(responseDB => {
        this.loadingPassword = false;
        this.message.status = null;
        if(Object.keys(responseDB.data.result).length) {
          if(responseDB.data.result.hasOwnProperty('CodeDeliveryDetails')) {
            console.log('Email sent');
            this.resetPassword = true;
          } else {
            this.resetPassword = false;
            this.message = {
              status: 0,
              text: responseDB.data.result.message
            }
          }
        } else {
          this.resetPassword = false;
          this.message = {
            status: 1,
            text: 'The password has been updated.'
          }

          setTimeout(() => {
            this.message.status = null;
          }, 6000);
        }
      });
    },
    updatePrivacy(){
      this.loading = true;
      axios.post(`${this.endpoint}/updateUserData`, {
        public: this.userPrivacy,
      }, {
        headers : {
          'Authorization': this.$store.state.jwtToken
        }
      }).then(responseDB => {
        this.loading = false;
        console.log(responseDB);
      });
    },
  }
}
</script>

<style lang="scss" scoped>
.two-factor-auth {
  max-width: 25rem;
}
.security-character {
  position: absolute;
  bottom: -0.5rem;
}

.switch-content-type {
  width: 44px;
}

.loader, .switch-content-type {
  margin: 20px auto 5px;
}
.wrapper-content {
  min-height: 140px;
}

.v-progress-linear {
  top: 50px;
}

.wrapper-reset-password {
  max-width: 500px;
  margin: 0 auto;
}
</style>
